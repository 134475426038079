import styled, { keyframes } from 'styled-components';

const FadeMoveDown = keyframes`
  0% {
    transform:translate(4px, 0) rotate(180deg);opacity:0;
  }
  50%{
    opacity:1;
  }
  100%{
    transform:translate(-4px, 0) rotate(180deg);opacity:0;
  }
`;

export default FadeMoveDown;