import styled from 'styled-components'

const Text = styled.p`
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.95px;
  line-height: 30px;
  max-width: 540px;
`;

export default Text;

