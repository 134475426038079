import React from "react"
import styled from 'styled-components'
import FluidImage from "../FluidImage";
import LinkTitle from "../LinkTitle/LinkTitle";
import { AnchorLink } from "gatsby-plugin-anchor-links";
const TileItem = styled.div`
   position: relative;
   margin-left: 20px;
   margin-right: 20px;
   color:  ${({theme}) => theme.colors.dark};
   :hover {
    text-decoration: none;
    .linkTitle {
       color: ${({theme}) => theme.colors.primary};
    }
   }
   ${({theme}) => theme.media.mobile} {
     margin-left: 15px;
     margin-right: 15px;
   }
`;
const ImageContainer = styled.div`
   height: 400px;
   ${({theme}) => theme.media.mobile} {
    height: auto;
   }
`;

const Tile = ({item, index}) => {
  return (
    <TileItem as={AnchorLink} to={item.link} className="text-center d-flex flex-column justify-content-center">
      <ImageContainer className="mb-3">
        <FluidImage className="maxHeightImg" image={item.image}/>
      </ImageContainer>
      <LinkTitle className="linkTitle px-3 mx-md-0" textCenter>{item.title}</LinkTitle>
    </TileItem>
  )
}

export default Tile
