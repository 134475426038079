import React, {Component} from "react";
import styled from 'styled-components';
import Tile from '../../components/Tile/Tile'
import H4 from '../../components/H4/H4'
import OverTitle from '../../components/OverTitle/OverTitle'
import Slider from "react-slick";

const StyledWrapper = styled.div`
  position: relative;
`;
export default class TilesSection extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
            dots: false
          }
        }
      ]
    };

    const overTitle = this.props.overTitle;
    const title = this.props.title;
    const tiles = this.props.tiles;

    return (
      <StyledWrapper id="TilesSection" className="container px-0 px-md-2 pt-5 pb-5 pb-0 pb-md-5 mt-0 mt-md-4">
        <div className="row justify-content-center text-center pt-3 px-4 px-md-0 flex-column">
          <OverTitle data-sal="fade"
                     data-sal-delay="300"
                     data-sal-duration="1000"
                     data-sal-easing="ease" textCenter className="text-uppercase">{overTitle}</OverTitle>
          <H4 data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="1000"
              data-sal-easing="ease" textCenter className="mt-0 mt-md-4">{title}</H4>
        </div>
        <div className="pt-3"
             data-sal="slide-up"
             data-sal-delay="300"
             data-sal-duration="1000"
             data-sal-easing="ease">
          <Slider ref={c => (this.slider = c)} {...settings}>
            {tiles.map((item, index) => <Tile item={item} index={(index + 1) * 300}/>)}
          </Slider>
        </div>
      </StyledWrapper>
    )
  }
}

