import React from "react"
import styled from 'styled-components';
import {Link} from 'gatsby';
import Button from '../../components/Button/Button';
import H4 from '../../components/H4/H4';
import OverTitle from '../../components/OverTitle/OverTitle';
import FluidImage from '../../components/FluidImage';
import Text from '../../components/Text/Text';


const StyledWrapper = styled.div`
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  ${({theme}) => theme.media.mobile} {
      margin-top: 40px;
      margin-bottom: 50px;
  }
`;

const LeftTextRightImage = ({title, text, image, button, overTitle}) => {

  return (
      <StyledWrapper className="container-fluid px-0 px-md-5 pt-0 pt-md-5 pb-0 pb-md-5">
        <div className="row px-md-5 align-items-center">
          <div className="col-12 col-md-6 px-3 px-md-0 pr-md-5 order-2 order-md-1 pt-3 pt-md-0 text-center text-md-left">
            <OverTitle data-sal="fade"
                       data-sal-delay="300"
                       data-sal-duration="1000"
                       data-sal-easing="ease" className="text-uppercase">{overTitle}</OverTitle>
            <H4 data-sal="slide-up"
                data-sal-delay="100"
                data-sal-duration="1000"
                data-sal-easing="ease" className="px-3 px-md-0 mt-4 mb-4">{title}</H4>
            <Text data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  className="px-3 px-md-0 mb-4">{text}</Text>
            <div data-sal="slide-up"
                 data-sal-delay="500"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
              <Button as={Link} to={button.url} className="mt-1">{button.title}</Button>
            </div>
          </div>
          <div data-sal="slide-up"
               data-sal-delay="100"
               data-sal-duration="1000"
               data-sal-easing="ease"
               className="col-12 col-md-6 px-3 px-md-0 pl-md-5 order-1 order-md-2">
            <FluidImage className="maxHeightImg" image={image}/>
          </div>
        </div>
      </StyledWrapper>
  )
}

export default LeftTextRightImage
