import React from "react"
import styled from 'styled-components';
import FluidImage from "../../components/FluidImage"
import H1 from '../../components/H1/H1';
import Title from '../../components/Title/Title';
import scrollTo from 'gatsby-plugin-smoothscroll';
import ArrowRight from "../../images/arrow-right.svg"
import FadeMoveDown from '../../style/keyframes/FadeMoveDown'


const StyledWrapper = styled.div`
  height: 100vh;
  background-color: ${({backgroundColor}) => backgroundColor};
  position: relative;
  min-height: 400px;
  height: calc(var(--vh, 1vh) * 100);
  ${({theme}) => theme.media.mobile} {
    min-height: -webkit-fill-available;
  }
`;

const LogoContainer = styled.div`
   max-width: 68px;
   width: 68px;
   float: right;
`;

const ArrowDown = styled.span`
  color: ${({textColor}) => textColor};
  font-size: 12px;
  letter-spacing: 1.71px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  cursor:pointer;
  font-variation-settings: 'wdth' 700,'wght' 400;
`;
const ArrowDownContainer = styled.span`
    right: 4px;
    transform: rotate(-90deg);
    bottom: 125px;
    ${({theme}) => theme.media.mobile} {
      left: -56px;
      right: auto;
      bottom: 106px;
    }
`;
const ImgArrow = styled.img`
    transform: rotate(180deg);
    margin-right: 18px;
    margin-bottom: 2px;
    animation: ${FadeMoveDown} 2s ease-in-out infinite;
`;

const LogoCol = styled.div`
    position: relative;
    right: auto;
    bottom: auto;
    ${({theme}) => theme.media.mobile} {
       position: absolute;
       bottom: 30px;
       right: 8px;
    }
`;

const HeaderHome = ({backgroundColor, textBottom, textColor, title, logo}) => {

  function handleClick() {
    scrollTo('#TilesSection')
  }

  return (
    <>
    <StyledWrapper backgroundColor={backgroundColor}
                   className="container-fluid d-flex flex-column justify-content-between px-3 px-md-5 pt-3 pt-md-5 pb-5">
      <div className="row justify-content-between">
        <div className="col-12 col-md-11">
          <H1 data-sal="slide-up"
              data-sal-delay="500"
              data-sal-duration="1000"
              data-sal-easing="ease"
              textColor={textColor} MaxWidth isBig>{title}</H1>
        </div>
        <LogoCol className="col-1">
          <LogoContainer data-sal="slide-up"
                         data-sal-delay="800"
                         data-sal-duration="1000"
                         data-sal-easing="ease">
            <FluidImage image={logo}/>
          </LogoContainer>
        </LogoCol>
      </div>
      <div className="row justify-content-between align-items-end">
        <div className="d-none d-md-block col-11" data-sal="slide-up"
             data-sal-delay="1000"
             data-sal-duration="1000"
             data-sal-easing="ease">
          <Title textColor={textColor} MaxWidth isBig>{textBottom}</Title>
        </div>
        <ArrowDownContainer className="text-right position: position-absolute" >
          <ArrowDown
            textColor={textColor} onClick={handleClick}>
            <span  data-sal="slide-up"
                   data-sal-delay="1000"
                   data-sal-duration="1000"
                   data-sal-easing="ease">
              <ImgArrow as="img" src={ArrowRight}/>
            </span>
            <span data-sal="slide-up"
                  data-sal-delay="1000"
                  data-sal-duration="1000"
                  data-sal-easing="ease">spójrz głębiej</span>

          </ArrowDown>
        </ArrowDownContainer>
      </div>
    </StyledWrapper>
    </>
  )
}

export default HeaderHome
