
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React from "react"

import Layout from "../src/components/Layout"
import SEO from "../src/components/SEO"

// Sections
import HeaderHome from '../src/layouts/HeaderHome';
import TilesSection from '../src/layouts/TilesSection';
import LeftTextRightImage from '../src/layouts/LeftTextRightImage';
import PostsList from '../src/layouts/PostsList';

const Page = ({ pageContext }) => {
  const {
    page: { title, pageBuilder },
  } = pageContext

  const layouts = pageBuilder.layouts || []
  const hiddenHeader = pageBuilder.hiddenHeader
  return (
    <Layout hiddenHeader={hiddenHeader}>
      <SEO title={title}/>
      {
        layouts.map((layout, index) => {
          
              if (layout.fieldGroupName === 'page_Pagebuilder_Layouts_HeaderHome') {
                  return <HeaderHome {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'page_Pagebuilder_Layouts_TilesSection') {
                  return <TilesSection {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'page_Pagebuilder_Layouts_LeftTextRightImage') {
                  return <LeftTextRightImage {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'page_Pagebuilder_Layouts_PostsList') {
                  return <PostsList {...layout} key={index} />;
              }
            
        })
      }

    </Layout>
  )
}

export default Page
  