import styled from 'styled-components'

const OverTitle = styled.span`
  font-weight: normal;
  color: ${({theme}) => theme.colors.primary};
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 17px;
  text-align: ${({textCenter}) => textCenter ? 'center' : 'left'};
  
   ${({theme}) => theme.media.mobile} {
    display: none;
  }
}
  
`;

export default OverTitle;