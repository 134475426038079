import React, {Component} from "react";
import styled from 'styled-components';
import Post from '../../components/Post/Post'
import H4 from '../../components/H4/H4'
import OverTitle from '../../components/OverTitle/OverTitle'
import Slider from "react-slick";

const StyledWrapper = styled.div`
  position: relative;
  
`;


export default class PostsList extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const overTitle = this.props.overTitle;
    const title = this.props.title;
    const posts = this.props.posts;

    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: false,
            dots: false
          }
        }
      ]
    };
    return (
      <StyledWrapper className="container-fluid px-0 px-md-5 pt-2 pt-md-5 pb-3 pb-md-4">
        <div className="row px-4 px-md-2 justify-content-center text-center flex-column">
          <OverTitle data-sal="fade"
                     data-sal-delay="300"
                     data-sal-duration="1000"
                     data-sal-easing="ease" textCenter className="text-uppercase">{overTitle}</OverTitle>
          <H4 data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="1000"
              data-sal-easing="ease" className="mt-4 text-center">{title}</H4>
        </div>
        <div data-sal="fade"
             data-sal-delay="300"
             data-sal-duration="1200"
             data-sal-easing="ease" className="text-center pt-4">
          <Slider ref={c => (this.slider = c)} {...settings}>
            {posts && posts.map((post, index) => <Post key={post.id} post={post} index={(index % 2 + 1) * 300}
                                                       margin="pb-4" col="mx-3 sal-animate" />)}
          </Slider>
        </div>
      </StyledWrapper>
    )
  }
}

